export default {
  namespaced: true,
  state: {
    choices: [],
    triggers: [],
  },
  getters: {
    choices: (state) => {
      return state.choices;
    },
    triggers: (state) => {
      return state.triggers;
    },
  },
  mutations: {
    UPDATE_CHOICES(state, val) {
      state.choices = val;
    },
    ADD_CHOICE(state, val) {
      if (!state.choices.includes(val)) state.choices.push(val);
    },
    REMOVE_CHOICE(state, val) {
      if (state.choices.includes(val)) state.choices.splice(state.choices.indexOf(val), 1);
    },

    UPDATE_TRIGGERS(state, val) {
      state.triggers = val;
    },
    ADD_TRIGGER(state, val) {
      if (state.triggers.filter((e) => e.question_id === val.question_id).length === 0) state.triggers.push(val);
    },
    REMOVE_TRIGGER(state, val) {
      for (let i = 0; i < state.triggers.length; i++) {
        if (state.triggers[i].question_id === val.question_id) {
          state.triggers.splice(i, 1);
          break;
        }
      }
    },
  },
};
